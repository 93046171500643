import {Route, Redirect} from "react-router-dom";
import { connect } from "react-redux";

const BasicRoute = ({component:Component, authenticated, ...rest}) => {

  return (
      <Route
       {...rest}
        render={
            (props)=> !authenticated ? (<Component {...props} />) : (
                <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: props.location
                    }}
                    />
            )
        }
        />
  )
}

const mapStateToProps = ({session}) => ({
    authenticated: session.authenticated
})

export default connect(mapStateToProps) (BasicRoute);
