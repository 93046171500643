import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TablePagination from "../../Pagination/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import "../MenuPage/MenuPage.css";

function MenuPage({ categoryData, tenant_id, success }) {
  const menuUrl = process.env.REACT_APP_MENUURL;
  const [menuData, setMenuData] = useState([]);
  const [menuRetrieved, setMenuRetrieved] = useState(false);

  const customerSearch = ""

  // Get User Data
  const getMenu = () => {
    const url = menuUrl + "/management/mandatory";
    fetch(url, {
      method: "GET",
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          setMenuData(() => result.data);
          setMenuRetrieved(() => true);
        } else {
          setMenuRetrieved(() => false);
        }
      });
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getMenu();
    }

    return () => {
      mounted = false;
    };
  }, [menuRetrieved]);

  const [page, setPage] = useState(0);
  const rowsPerPage = 7;

  const [index, setIndex] = useState(1);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, onPageChange } = props;

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
      setIndex(index - 7);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);

      setIndex(index + 7);
    };

    return (
      <div className="containerbutton">
        <button
          onClick={handleBackButtonClick}
          disabled={page === 0}
          className={page === 0 ? "leftdisabledbutton" : "leftdisplaybutton"}
        >
          {" "}
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={page === 0 ? { color: "#BEBEBE" } : { color: "#949494" }}
          />
        </button>

        <button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / 7) - 1}
          className={
            page >= Math.ceil(count / 7) - 1
              ? "rightdisabledbutton"
              : "rightdisplaybutton"
          }
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            style={
              page >= Math.ceil(count / 7) - 1
                ? { color: "#BEBEBE" }
                : { color: "#949494" }
            }
          />
        </button>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function AddMenuToTenant(props) {
    const [categoryId, setCategoryId] = useState("");
    const addMenu = () => {
      const url = menuUrl + "/management/mandatory/inject";
      fetch(url, {
        method: "POST",
        headers: { "content-type": "application/JSON" },
        body: JSON.stringify({
          tenant_id: tenant_id,
          category_id: categoryId,
          mandatory_menu: props.menu
        })
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status.toUpperCase() === "SUCCESS") {
            props.success(true)
          }
        });
    }

    return (
      <div style={{ display: 'flex' }}>
        <select className="selectCategory" onChange={e => setCategoryId(e.target.value)}>
          <option value="">Select Category</option>
          {
            props.categoryData.map(item => (
              <option value={item.id}>{item.name}</option>
            ))
          }
        </select>
        <button onClick={addMenu} className="addmenubtn" style={{ marginLeft: 12 }}>Add</button>
      </div>
    )
  }

  return (
    menuRetrieved ? (
        <div className="outercustomertable">
          <div className="customertable">
            <div className="border">
              <div className="innercustomertable">
                <div className="menumandatoryheadertitlegrid">
                  <div className="customerheadertitle">NO</div>
                  <div className="customerheadertitle">IMAGE</div>
                  <div className="customerheadertitle">NAME</div>
                  <div className="customerheadertitle">PRICE</div>
                  <div className="customerheadertitle">QUANTITY</div>
                  <div className="customerheadertitle">DURATION</div>
                  <div className="customerheadertitle">ACTION</div>
                </div>

                <div className="customerrendercontainer">
                  {(rowsPerPage > 0
                    ? menuData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : menuData
                  ).map((post, i) => {
                    return (
                      <div className={i != 6 ? "bordered" : "noborder"}>
                        <div className="menumandatoryrendergrid">
                          <div className="customertext">{i + index}</div>
                          <div className="customertext">
                            <img height={40} src={`${process.env.REACT_APP_BASEURL}/api${post.menuImage}`} alt="menu image"/>
                          </div>
                          <div className="customertext">{post.name || "-"}</div>
                          <div className="customertext">Rp {parseInt(post?.price)?.toLocaleString('id-ID') ?? "0"}</div>
                          <div className="customertext">{post.quantity || "-"}</div>
                          <div className="customertext">{post.duration || "-"}</div>
                          <div className="customertext">
                            <AddMenuToTenant categoryData={categoryData} menu={post} success={val => success(val)} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="tenantfooter">
            <TablePagination
              colSpan={3}
              count={menuData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </div>
    ) : (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots color="#f10c0c" height={80} width={80} />
      </div>
    )
  );
}

export default MenuPage;
