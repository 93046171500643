import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TablePagination from "../../Pagination/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faXmark,
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useMinimalSelectStyles } from "./select/index";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import "./MenuPage.css";
import Compressor from "compressorjs";

function MenuPage() {
  const menuUrl = process.env.REACT_APP_MENUURL;
  const [menuData, setMenuData] = useState([]);
  const [menuRetrieved, setMenuRetrieved] = useState(false);

  const customerSearch = ""

  // Get User Data
  const getMenu = () => {
    const url = menuUrl + "/management/mandatory";
    fetch(url, {
      method: "GET",
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          setMenuData(() => result.data);
          setMenuRetrieved(() => true);
        } else {
          setMenuRetrieved(() => false);
        }
      });
  }
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getMenu();
    }

    return () => {
      mounted = false;
    };
  }, [menuRetrieved]);

  const [page, setPage] = useState(0);
  const rowsPerPage = 7;

  const [index, setIndex] = useState(1);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, onPageChange } = props;

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
      setIndex(index - 7);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);

      setIndex(index + 7);
    };

    return (
      <div className="containerbutton">
        <button
          onClick={handleBackButtonClick}
          disabled={page === 0}
          className={page === 0 ? "leftdisabledbutton" : "leftdisplaybutton"}
        >
          {" "}
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={page === 0 ? { color: "#BEBEBE" } : { color: "#949494" }}
          />
        </button>

        <button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / 7) - 1}
          className={
            page >= Math.ceil(count / 7) - 1
              ? "rightdisabledbutton"
              : "rightdisplaybutton"
          }
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            style={
              page >= Math.ceil(count / 7) - 1
                ? { color: "#BEBEBE" }
                : { color: "#949494" }
            }
          />
        </button>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataBody, setDataBody] = useState({
    name: '',
    menuImage: '',
    duration: null,
    description: '',
    price: null,
    quantity: null
  });
  const [menuImage, setMenuImage] = useState('');
  async function imageHandler(e) {
    const file = e.target.files[0];
    setDataBody(val => ({
      ...val,
      menuImage: file
    }));
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setMenuImage(() => reader.result);
      }
    };
    reader.readAsDataURL(file);
  }
  async function handleSubmitMenu() {
    setButtonDisabled(true)
    const createUrl = menuUrl + "/management/mandatory";
    const form = new FormData();
    form.append('description', dataBody.description);
    form.append('quantity', dataBody.quantity);
    form.append('price', dataBody.price);
    form.append('name', dataBody.name);
    form.append('menuImage', dataBody.menuImage);
    form.append('duration', dataBody.duration);
    fetch(createUrl, {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toLowerCase() === 'success') {
          setButtonDisabled(false);
          setCreateMenuOpen(false);
          getMenu();
        }
      });
  }
  const [menuId, setMenuId] = useState('');
  const openModalEdit = (id) => {
    setMenuId(() => id);
    const url = menuUrl + "/management/mandatory/" + id;
    fetch(url, {
      method: "GET",
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          const data = result.data
          setDataBody(() => ({
            name: data.name,
            quantity: data.quantity,
            price: data.price,
            duration: data.duration,
            description: data.description,
            menuImage: data.menuImage,
          }));
          setMenuImage(() => `${process.env.REACT_APP_BASEURL}/api${data.menuImage}`);
          setCreateMenuOpen(() => true)
        }
      });
  }

  const handleCancel = () => {
    setCreateMenuOpen(false);
    setMenuId(() => '');
    setDataBody(() => ({
      name: '',
      quantity: null,
      duration: null,
      price: null,
      description: '',
      menuImage: ''
    }));
    setMenuImage(() => '');
  }

  const handleSubmitEditMenu = () => {
    setButtonDisabled(true)
    const createUrl = menuUrl + "/management/mandatory";
    const form = new FormData();
    form.append('mandatory_menu_id', menuId);
    form.append('description', dataBody.description);
    form.append('quantity', dataBody.quantity);
    form.append('price', dataBody.price);
    form.append('name', dataBody.name);
    form.append('menuImage', dataBody.menuImage);
    form.append('duration', dataBody.duration);
    fetch(createUrl, {
      method: "PUT",
      body: form,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toLowerCase() === 'success') {
          setButtonDisabled(false);
          setCreateMenuOpen(false);
          getMenu();
          handleCancel();
        }
      });
  }

  const [openModalDelete, setOpenModalDelete] = useState(false);
  function handleRemoveMenu() {
    const url = menuUrl + "/management/mandatory/" + menuId;

    fetch(url, {
      method: "DELETE",
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          setOpenModalDelete(false);
          getMenu();
        }
      });
  }

  return (
    <div className="container" style={{ zoom:"60%" }}>
      <Modal open={openModalDelete}>
        <Box className="deletefoodcourtmodalbox" style={{ zoom:"80%" }}>
          <div className="modalheader" >Delete Menu ?</div>
          <div className="buttonmodalbox">
            <div
              onClick={() => {
                setOpenModalDelete(false);
              }}
              className="cancelbtn"
            >
              Cancel
            </div>
            <div onClick={handleRemoveMenu} className="submitbtn">
              Delete
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={createMenuOpen}>
        <Box className="createfoodcourtmodalbox">
          <div className="modalboxtitle">
            { menuId ? 'Edit Menu' : 'Add New Menu' }
          </div>
          <div className="inputcreatemodalbox">
            <div className="leftcolumncontainer">
              <div className="labelcolumn">
                <div className="settingslabeltext">Name</div>
                <div className="settingsinputcontainer">
                  <input type="text" value={dataBody.name} className="inputcontainer1" onChange={(e) => setDataBody(val => ({
                    ...val,
                    name: e.target.value
                  }))} />
                </div>
              </div>
              <div className="labelcolumn">
                <div className="settingslabeltext">Duration</div>
                <div className="settingsinputcontainer">
                  <input type="number" value={dataBody.duration} className="inputcontainer1" onChange={(e) => setDataBody(val => ({
                    ...val,
                    duration: e.target.value
                  }))} />
                </div>
              </div>
              <div className="labelcolumn">
                <div className="settingslabeltext">Price</div>
                <div className="settingsinputcontainer">
                  <input type="number" value={dataBody.price} className="inputcontainer1" onChange={(e) => setDataBody(val => ({
                    ...val,
                    price: e.target.value
                  }))} />
                </div>
              </div>
              <div className="labelcolumn">
                <div className="settingslabeltext">Quantity</div>
                <div className="settingsinputcontainer">
                  <input type="number" value={dataBody.quantity} className="inputcontainer1" onChange={(e) => setDataBody(val => ({
                    ...val,
                    quantity: e.target.value
                  }))} />
                </div>
              </div>
              <div className="labelcolumn">
                <div className="settingslabeltext">Description</div>
                <div className="settingsinputcontainer">
                  <textarea value={dataBody.description} className="inputcontainer1" onChange={(e) => setDataBody(val => ({
                    ...val,
                    description: e.target.value
                  }))} />
                </div>
              </div>
            </div>

            <div className="rightcolumncontainer">
              <div className="settingslabeltext">Menu Picture</div>
              <div className="editprofileimagecontainer">
                <div className="customButton">
                  <img src={menuImage} className="editprofileimage1" />

                  <div className="editprofileimagebuttoncontainer">
                    <div className="imagebuttoncontainer">
                      <div className="productimagebutton">
                        <label htmlFor="file-input">
                          <FontAwesomeIcon icon={faPencil} className="promoinput" />
                        </label>

                        <input id="file-input" type="file" name="foodcourt" accept=".png, .jpg" className="productinputfile" onChange={imageHandler} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttonmodalbox" style={{ marginTop: 0 }}>
            <div
              onClick={handleCancel}
              className="cancelbtn"
            >
              Cancel
            </div>
            <div
              onClick={buttonDisabled ? null : menuId ? handleSubmitEditMenu : handleSubmitMenu}
              className= {buttonDisabled ? "disabledbtn" : "submitbtn"}>
                {buttonDisabled ? "Saving..." : "Submit"}
            </div>
          </div>
        </Box>
      </Modal>
      {menuRetrieved ? (
        <div className="customercontainer">
          <div className="tenantheader">
            <div className="tenantleft">Total Menu ({menuData.length})</div>
            <div style={{ marginLeft: 'auto' }}>
              <button onClick={() => setCreateMenuOpen(true)} className="addmenubtn">+ Add Menu</button>
            </div>
          </div>

          {customerSearch == "" ? (
            <div className="outercustomertable">
              <div className="customertable">
                <div className="border">
                  <div className="innercustomertable">
                    <div className="customerheader">
                      <div className="customerleft" style={{ marginRight: 'auto' }}>All Menu</div>
                    </div>
                    <div className="menumandatoryheadertitlegrid">
                      <div className="customerheadertitle">NO</div>
                      <div className="customerheadertitle">IMAGE</div>
                      <div className="customerheadertitle">NAME</div>
                      <div className="customerheadertitle">PRICE</div>
                      <div className="customerheadertitle">QUANTITY</div>
                      <div className="customerheadertitle">DURATION</div>
                      <div className="customerheadertitle">ACTION</div>
                    </div>

                    <div className="customerrendercontainer">
                      {(rowsPerPage > 0
                        ? menuData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : menuData
                      ).map((post, i) => {
                        return (
                          <div className={i != 6 ? "bordered" : "noborder"}>
                            <div className="menumandatoryrendergrid">
                              <div className="customertext">{i + index}</div>
                              <div className="customertext">
                                <img height={40} src={`${process.env.REACT_APP_BASEURL}/api${post.menuImage}`} alt="menu image"/>
                              </div>
                              <div className="customertext">{post.name || "-"}</div>
                              <div className="customertext">Rp {parseInt(post?.price)?.toLocaleString('id-ID') ?? "0"}</div>
                              <div className="customertext">{post.quantity || "-"}</div>
                              <div className="customertext">{post.duration || "-"}</div>
                              <div>
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  onClick={() => openModalEdit(post.mandatory_menu_id)}
                                  style={{ cursor: 'pointer', marginRight: 16 }} />
                                <FontAwesomeIcon onClick={() => {
                                  setOpenModalDelete(true);
                                  setMenuId(post.mandatory_menu_id)
                                }} icon={faTrash} style={{ cursor: 'pointer' }} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tenantfooter">
                <TablePagination
                  colSpan={3}
                  count={menuData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </div>
          ) : (
            <div className="outercustomertable">
              <div className="customertable">
                <div className="border">
                  <div className="innercustomertable">
                    <div className="customerheader">
                      <div>All Menu</div>
                    </div>
                    <div className="menumandatoryheadertitlegrid">
                      <div className="customerheadertitle">NO</div>
                      <div className="customerheadertitle">IMAGE</div>
                      <div className="customerheadertitle">NAME</div>
                      <div className="customerheadertitle">PRICE</div>
                      <div className="customerheadertitle">QUANTITY</div>
                      <div className="customerheadertitle">DURATION</div>
                      <div className="customerheadertitle">ACTION</div>
                    </div>

                    <div className="searchcustomerrendercontainer">
                      {menuRetrieved === true &&
                        menuData
                          .filter((post, a) => {
                            if (
                              post.name !== undefined &&
                              post.name
                                .toLowerCase()
                                .includes(customerSearch.toLowerCase())
                            ) {
                              return post;
                            }
                          })

                          .map((post, i) => {
                            return (
                              <div className="bordered">
                                <div className="menumandatoryrendergrid">
                                  <div className="customertext">{i + index}</div>
                                  <div className="customertext">
                                    <img height={40} src={`${process.env.REACT_APP_BASEURL}/api${post.menuImage}`} alt="menu image"/>
                                  </div>
                                  <div className="customertext">{post.name || "-"}</div>
                                  <div className="customertext">Rp {parseInt(post?.price)?.toLocaleString('id-ID') ?? "0"}</div>
                                  <div className="customertext">{post.quantity || "-"}</div>
                                  <div className="customertext">{post.duration || "-"}</div>
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faPencil}
                                      onClick={() => openModalEdit(post.mandatory_menu_id)}
                                      style={{ cursor: 'pointer', marginRight: 16 }} />
                                    <FontAwesomeIcon onClick={() => {
                                      setOpenModalDelete(true);
                                      setMenuId(post.mandatory_menu_id)
                                    }} icon={faTrash} style={{ cursor: 'pointer' }} />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <ThreeDots color="#f10c0c" height={80} width={80} />
        </div>
      )}
    </div>
  );
}

export default MenuPage;
